import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap, Polygon, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './index.css';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

const PolygonMap = () => {
  const [polygonCoords, setPolygonCoords] = useState([]);
  const [savedPolygons, setSavedPolygons] = useState([]);
  const [inputCoords, setInputCoords] = useState('');
  const [showPolygons, setShowPolygons] = useState(true);
  const [placemarks, setPlacemarks] = useState([]);
  const mapRef = useRef(null);

  // Modify the useEffect hook to load both polygons and placemarks
  useEffect(() => {
    loadFromLocalStorage();
  }, []);

  const addPolygonPoint = () => {
    const [lat, lng] = inputCoords.split(',').map(coord => parseFloat(coord.trim()));
    if (!isNaN(lat) && !isNaN(lng)) {
      setPolygonCoords([...polygonCoords, [lat, lng]]);
      setInputCoords('');
    }
  };

  const deletePolygonPoint = (index) => {
    const newCoords = polygonCoords.filter((_, i) => i !== index);
    setPolygonCoords(newCoords);
  };

  const savePolygon = () => {
    if (polygonCoords.length > 2) {
      const newSavedPolygons = [...savedPolygons, polygonCoords];
      setSavedPolygons(newSavedPolygons);
      localStorage.setItem('savedPolygons', JSON.stringify(newSavedPolygons));
      console.log('Saved polygons to localStorage:', newSavedPolygons);
      setPolygonCoords([]);
      setShowPolygons(true);
    }
  };

  const addPlacemark = () => {
    if (inputCoords.trim() === '') {
      // If input is empty, use current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const newPlacemarks = [...placemarks, [latitude, longitude]];
            setPlacemarks(newPlacemarks);
            localStorage.setItem('savedPlacemarks', JSON.stringify(newPlacemarks));
            // Optionally, you can center the map on the new placemark
            if (mapRef.current) {
              mapRef.current.flyTo([latitude, longitude], 13);
            }
          },
          (error) => {
            console.error("Error getting location:", error);
            alert("Unable to get current location. Please enter coordinates manually.");
          }
        );
      } else {
        alert("Geolocation is not supported by this browser. Please enter coordinates manually.");
      }
    } else {
      // Existing code for manual coordinate input
      const [lat, lng] = inputCoords.split(',').map(coord => parseFloat(coord.trim()));
      if (!isNaN(lat) && !isNaN(lng)) {
        const newPlacemarks = [...placemarks, [lat, lng]];
        setPlacemarks(newPlacemarks);
        localStorage.setItem('savedPlacemarks', JSON.stringify(newPlacemarks));
        setInputCoords('');
      }
    }
  };

  const loadFromLocalStorage = () => {
    const storedPolygons = localStorage.getItem('savedPolygons');
    const storedPlacemarks = localStorage.getItem('savedPlacemarks');
    
    if (storedPolygons) {
      try {
        const parsedPolygons = JSON.parse(storedPolygons);
        setSavedPolygons(parsedPolygons);
      } catch (error) {
        console.error('Error parsing stored polygons:', error);
      }
    }
    
    if (storedPlacemarks) {
      try {
        const parsedPlacemarks = JSON.parse(storedPlacemarks);
        setPlacemarks(parsedPlacemarks);
      } catch (error) {
        console.error('Error parsing stored placemarks:', error);
      }
    }
  };

  const renderPolygons = () => {
    if (savedPolygons.length > 0 || polygonCoords.length > 2) {
      setShowPolygons(true);
      const allPolygons = [...savedPolygons, polygonCoords];
      const bounds = L.latLngBounds(allPolygons.flat());
      mapRef.current.fitBounds(bounds);
    }
  };

  const removeSavedPolygon = (index) => {
    const newSavedPolygons = savedPolygons.filter((_, i) => i !== index);
    setSavedPolygons(newSavedPolygons);
    localStorage.setItem('savedPolygons', JSON.stringify(newSavedPolygons));
    renderPolygons();
  };

  const removePlacemark = (index) => {
    const newPlacemarks = placemarks.filter((_, i) => i !== index);
    setPlacemarks(newPlacemarks);
    localStorage.setItem('savedPlacemarks', JSON.stringify(newPlacemarks));
  };

  const goToPlacemark = (coords) => {
    if (mapRef.current) {
      mapRef.current.flyTo(coords, 18); // Changed zoom level to 20
    }
  };

  const goToPolygon = (coords) => {
    if (mapRef.current) {
      const bounds = L.latLngBounds(coords);
      mapRef.current.fitBounds(bounds);
    }
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <div className="mb-4 p-4">
        <input
          type="text"
          value={inputCoords}
          onChange={(e) => setInputCoords(e.target.value)}
          placeholder="Latitude, Longitude"
          className="mr-2 p-2 border rounded"
        />
        <button onClick={addPolygonPoint} className="p-2 bg-blue-500 text-white rounded mr-2">
          Add Point
        </button>
        <button onClick={addPlacemark} className="p-2 bg-green-500 text-white rounded">
          Add Placemark
        </button>
      </div>

      <div className="flex justify-between w-full px-4 mb-4">
        <div className="w-1/2 p-4 max-h-40 overflow-y-auto border-r">
          <h3 className="font-bold mb-2">Current Polygon Coordinates:</h3>
          <ul>
            {polygonCoords.map((coord, index) => (
              <li key={index} className="mb-1">
                [{coord[0].toFixed(6)}, {coord[1].toFixed(6)}]
                <button
                  onClick={() => deletePolygonPoint(index)}
                  className="ml-2 p-1 bg-red-500 text-white rounded text-xs"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-1/2 p-4 max-h-40 overflow-y-auto">
          <h3 className="font-bold mb-2">Saved Polygons:</h3>
          <ul>
            {savedPolygons.map((polygon, polygonIndex) => (
              <li key={polygonIndex} className="mb-2">
                Polygon {polygonIndex + 1}:
                <button
                  onClick={() => removeSavedPolygon(polygonIndex)}
                  className="ml-2 p-1 bg-red-500 text-white rounded text-xs"
                >
                  Remove
                </button>
                <button
                  onClick={() => goToPolygon(polygon)}
                  className="ml-2 p-1 bg-blue-500 text-white rounded text-xs"
                >
                  Go to
                </button>
                <ul className="ml-4">
                  {polygon.map((coord, coordIndex) => (
                    <li key={coordIndex}>
                      [{coord[0].toFixed(6)}, {coord[1].toFixed(6)}]
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
  
          <h3 className="font-bold mb-2 mt-4">Placemarks:</h3>
          <ul>
            {placemarks.map((placemark, index) => (
              <li key={index} className="mb-1 flex items-center">
                [{placemark[0].toFixed(6)}, {placemark[1].toFixed(6)}]
                <button
                  onClick={() => removePlacemark(index)}
                  className="ml-2 p-1 bg-red-500 text-white rounded text-xs"
                >
                  Remove
                </button>
                <button
                  onClick={() => goToPlacemark(placemark)}
                  className="ml-2 p-1 bg-blue-500 text-white rounded text-xs"
                >
                  Go to
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mb-4">
        <button 
          onClick={savePolygon} 
          className="p-2 bg-purple-500 text-white rounded mr-2"
          disabled={polygonCoords.length < 3}
        >
          Save Polygon
        </button>
        <button 
          onClick={renderPolygons} 
          className="p-2 bg-green-500 text-white rounded"
          disabled={(savedPolygons.length === 0 && polygonCoords.length < 3)}
        >
          Render Polygons
        </button>
      </div>

      <MapContainer 
        center={[0, 0]} 
        zoom={2} 
        style={{ height: 'calc(100% - 200px)', width: '100%' }}
        ref={mapRef}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {showPolygons && (
          <>
            {savedPolygons.map((coords, index) => (
              <Polygon key={index} positions={coords}>
                <Tooltip sticky>Polygon {index + 1}</Tooltip>
              </Polygon>
            ))}
            {polygonCoords.length > 2 && (
              <Polygon positions={polygonCoords}>
                <Tooltip sticky>Current Polygon</Tooltip>
              </Polygon>
            )}
          </>
        )}
        {placemarks.map((coords, index) => (
          <Marker key={`placemark-${index}`} position={coords} />
        ))}
        <LocationControl />
      </MapContainer>
    </div>
  );
};

const LocationControl = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const map = useMap();

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation([latitude, longitude]);
          map.flyTo([latitude, longitude], 13);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <div className="leaflet-top leaflet-left">
        <div className="leaflet-control leaflet-bar">
          <button onClick={getCurrentLocation} className="p-2 bg-green-500 text-white rounded">
            Get Current Location
          </button>
        </div>
      </div>
      {currentLocation && <Marker position={currentLocation} />}
    </>
  );
};

export default PolygonMap;